// Helper functions

// Returns a boolean determining if the passed value is an object or not.
// https://www.30secondsofcode.org/js/s/is-object/
export const isObject = obj => obj === Object(obj);

// Inverts the key-value pairs of an object, without mutating it. The corresponding inverted value of each inverted key is an array of keys responsible for generating the inverted value.
// https://www.30secondsofcode.org/js/s/invert-key-values/
export const invertKeyValues = (obj, fn) =>
  Object.keys(obj).reduce((acc, key) => {
    const val = fn ? fn(obj[key]) : obj[key];
    acc[val] = acc[val] || [];
    acc[val].push(key);
    return acc;
  }, {});

export const logChangedProperties = (newProperties, changedProperties) => {
  changedProperties.forEach((oldValue, propName) => {
    if (isObject(oldValue)) {
      Object.keys(changedProperties.get(propName)).forEach(subPropertyName => {
        let oldSubPropertyValue = changedProperties.get(propName)[subPropertyName];
        let newSubPropertyValue = newProperties[propName][subPropertyName];
        if (isObject(oldSubPropertyValue)) oldSubPropertyValue = JSON.stringify(oldSubPropertyValue);
        if (isObject(newSubPropertyValue)) newSubPropertyValue = JSON.stringify(newSubPropertyValue);
        if (oldSubPropertyValue !== newSubPropertyValue)
          console.info(
            `Property ${propName}.${subPropertyName} changed. oldValue: ${oldSubPropertyValue} newValue: ${newSubPropertyValue}`,
          );
      });
    } else {
      let newPropertyValue = newProperties[propName];
      if (isObject(newPropertyValue)) newPropertyValue = JSON.stringify(newPropertyValue);
      console.info(`Property ${propName} changed. oldValue: ${oldValue} newValue: ${newPropertyValue}`);
    }
  });
};

// Check if the object has the key
// https://eslint.org/docs/rules/no-prototype-builtins
export const hasKey = (object, key) => Object.prototype.hasOwnProperty.call(object, key);

// Firebase Push ID generator
// via https://gist.github.com/mikelehen/3596a30bd69384624c11
export const generatePushID = (() => {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  let lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  const lastRandChars = [];

  return () => {
    let now = new Date().getTime();
    const duplicateTime = now === lastPushTime;
    lastPushTime = now;

    const timeStampChars = new Array(8);
    let i;
    for (i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0) throw new Error('We should have converted the entire timestamp.');

    let id = timeStampChars.join('');

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i] += 1;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length !== 20) throw new Error('Length should be 20.');

    return id;
  };
})();

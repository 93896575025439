// Functions for the Firebase Authentication
// https://firebase.google.com/docs/auth/web/start

import 'firebase/auth';
import { firebase } from '../modules/firebase-init';

export const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      window.history.pushState({}, '', '/');
    })
    .catch(error => {
      console.error('Firebase sign out error:', error.code, error.message);
    });
};

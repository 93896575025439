// Initialize Firebase
// https://firebase.google.com/docs/web/setup

import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyA0OpvyB_6AAHhhUbvdqrp98xhEBzYZkgM',
  authDomain: 'behavio.firebaseapp.com',
  databaseURL: 'https://behavio-acls.firebaseio.com',
  projectId: 'behavio',
  storageBucket: 'behavio.appspot.com',
  messagingSenderId: '58563161581',
  appId: '1:58563161581:web:52fa0dd8e4e651f0',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };

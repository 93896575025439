// Functions for the Firebase Realtime Database
// https://firebase.google.com/docs/database/web/start

import 'firebase/database';
import { firebase } from '../modules/firebase-init';

// Encode firebase key string to escape unsafe characters (.$[]#/).
export const encodeKey = key => encodeURIComponent(key).replace(/\./g, '%2E');

export const decodeKey = key => decodeURIComponent(key).replace('%2E', '.');

export const databaseReference = path => firebase.database().ref(path);

export const getArray = (path, keyName, options) =>
  new Promise(async resolve => {
    const snapshot = await getSnapshot(path, options);
    const array = [];
    if (snapshot.val()) {
      snapshot.forEach(child => {
        array.push(Object.assign({}, child.val(), { [keyName]: child.key }));
      });
    }
    resolve(array);
  });

export const getObject = (path, options) => getValue(path, {}, options);

export const getSnapshot = (path, options = {}) => {
  let reference = databaseReference(path);

  if (options.orderByChild) reference = reference.orderByChild(options.orderByChild);
  if (options.startAt) reference = reference.startAt(options.startAt);
  if (options.limitToFirst) reference = reference.limitToFirst(options.limitToFirst);

  return new Promise((resolve, reject) => {
    reference
      .once('value', snapshot => {
        resolve(snapshot);
      })
      .catch(error => {
        console.error('Firebase database error:', error.code, error.message);
        reject(error);
      });
  });
};

export const getValue = (path, defaultValue, options) =>
  new Promise(async resolve => {
    const snapshot = await getSnapshot(path, options);
    resolve(snapshot.val() || defaultValue);
  });

export const pushItem = (path, object) =>
  new Promise((resolve, reject) => {
    const reference = firebase
      .database()
      .ref(path)
      .push();
    reference.set(object, error => {
      if (error) {
        console.error('Firebase database error:', error.code, error.message);
        reject(error);
      } else {
        resolve(reference.key);
      }
    });
  });

export const removeItemByChildValue = (path, child, value) =>
  new Promise((resolve, reject) => {
    const reference = firebase
      .database()
      .ref(path)
      .orderByChild(child)
      .equalTo(value);
    reference.once('value').then(snapshot =>
      snapshot.forEach(snapshotChild => {
        snapshot.ref.child(snapshotChild.key).remove(error => {
          if (error) {
            console.error('Firebase database error:', error.code, error.message);
            reject(error);
          } else {
            resolve();
          }
        });
      }),
    );
  });

export const removeKey = path =>
  new Promise((resolve, reject) => {
    databaseReference(path).remove(error => {
      if (error) {
        console.error('Firebase database error:', error.code, error.message);
        reject(error);
      } else {
        resolve();
      }
    });
  });

export const setValue = (path, value) =>
  new Promise((resolve, reject) => {
    databaseReference(path).set(value, error => {
      if (error) {
        console.error('Firebase database error:', error.code, error.message);
        reject(error);
      } else {
        resolve();
      }
    });
  });

export const updateValue = (path, value) =>
  new Promise((resolve, reject) => {
    databaseReference(path).update(value, error => {
      if (error) {
        console.error('Firebase database error:', error.code, error.message);
        reject(error);
      } else {
        resolve();
      }
    });
  });
